<template>

  <div style="float:right" class="btn btn-god" :class="{'active': flag_open}"
       @click="open_all">Открыть все</div>
  <h1>Монитор операций (Прошлая версия)</h1>
  <div class="wrp-operations">
    <div class="operation" v-for="operation in operations" :key="operation.id"
         :class="{ 'open': flag_open == true, 'close': flag_open == false}"
         style="position:relative">
      <router-link :to="operation.name" class="inner-operation" >
        <div class="inner-operation--title">{{ operation.label }} ({{operation.cnt}})</div>
        <div v-for="(order, index) in operation.orders" :key="index" class="order-info" :class="{
            'white': order.statusBuff > 100,
            'green' : order.statusBuff >= 66 && order.statusBuff <= 100,
            'yellow': order.statusBuff >= 33 && order.statusBuff < 66,
            'red': order.statusBuff >= 0 && order.statusBuff < 33,
            'black': order.statusBuff < 0,
            }">{{order.priority}}. {{order.number}}</div>
      </router-link>
      <!--
      <a href="javascript:void(0)" class="btn-collapse">
        <i class="fa fa-angle-double-down" aria-hidden="true"></i>
      </a>
      -->
    </div>
  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: 'Operations',
  data() {
    return {
      operations: [],
      flag_open: false,
      error: [],
    };
  },
  created() {
    this.getAllOperations();
  },
  methods: {
    getAllOperations() {
      const str = '/api/list-operations-test';
      axios.get(str)
        .then((res) => {
          if (res.data.success === 1) {
            this.operations = res.data.list_operation;
          }
        })
        .catch((e) => {
          this.error.push(e);
        });
    },
    open_all() {
      if (this.flag_open) {
        this.flag_open = false;
      } else {
        this.flag_open = true;
      }
    },
  },
};
</script>

<style scoped>
.order-info{ margin-right:-10px; margin-left:-10px; padding:0px 10px;}

.wrp-operations{
  margin-left: -12px;
  margin-right: -12px;
  display:flex; flex-wrap: wrap;
}
.operation{
  padding:12px;
  overflow:hidden;
  position:relative;
}

.inner-operation{
  display:block;
  text-decoration:none;
  /* color: #2c3e50; */
  /* background: #c0dfcb; */
  color:#eee;
  background: #3e5977;
  border: 1px solid #a8c8b3;
  padding:5px 10px 0px;
  border-radius:4px
}
.inner-operation:hover{
  /*
  background: #afd2bb;
  border: 1px solid #9dbba7;
  */
  background: #537499;
}

.inner-operation--title{font-weight:bold; padding-bottom:6px;}

.black{background: #14171a; color: #eee;}
.red{background: #e33838; color: #eee;}
.yellow{background: #f2f251; color: #000;}
.green{background: #2e842e; color: #eee;}
.white{background: #ffffff; color: #111;}
.open{height:auto}
.close{max-height:410px;}

.btn-collapse{display:block; text-align:center; color:#fff;
  position:absolute; bottom:0px; left:0px; width:100%; background:#537499;}
.btn-god{padding: 4px 10px; box-shadow: 1px 1px 1px #000; background: #c1c1c1;
  transition:1s; cursor:pointer; margin-top:6px;}
.btn-god.active{ box-shadow: 1px 1px 1px #000 inset; background: #bbe3c1;}
</style>
